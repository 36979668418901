/* Custom dialog styles */
#popup_title {
  margin: 0em;
  padding: 1em 1.75em 0em 1.75em;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  line-height: 1.75em;
  color: #212121;
  cursor: default;
}
#popup_container {
  font-family: AntipastoRegular, sans-serif;
  font-size: 14px;
  min-width: 300px;
  max-width: 600px;
  background: #FFFFFF;
  color: #212121;
  border: solid 1px #D3D8DB;
}
#popup_content {
  padding: 1em 1.75em;
  margin: 0em;
}
#popup_message {
  margin:0px auto;
}
#popup_panel {
  text-align: center;
  margin: 1em 0em 0em 1em;
}
#popup_prompt {
  margin: .5em 0em;
}
#popup_container INPUT[type='button'] {
  margin: 0px 5px 0px 5px;
  MIN-width: 100px;
  height: 30px;
  border: solid 1px #F4A700;
  color: #FFFFFF;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDB4rn+FCkAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/iynBEABC8BnSLN56YAAAAASUVORK5CYII=');
}
#popup_overlay {
  display: block !important;
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  z-index: 36 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: #212121 !important;
  opacity: 0.3 !important;
}